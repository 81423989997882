import axios from "@/api/axios"; // 引用axios

export const getSysParamAll = (params) => {
    return axios({
        url: "/api/sysParam/getSysParamAll",
        method: "post",
        params: params
    });
};

export const addData = (params) => {
    return axios({
        url: "/api/sysParam/addData",
        method: "post",
        params: params
    });
};

export const editData = (params) => {
    return axios({
        url: "/api/sysParam/editData",
        method: "post",
        params: params
    });
};

export const delData = (params) => {
    return axios({
        url: "/api/sysParam/del",
        method: "post",
        params: params
    });
};