<template>
  <div style="width: 40%;margin: 0 auto">
    <el-form ref="form" label-position="left" label-width="120px" :model="form">
      <el-row :gutter="20" style="text-align: left">
        <el-col :span="24"><el-form-item label="版本信息"></el-form-item></el-col>
        <el-col :span="24">
          <el-form-item label="运行时间">
            <span>{{form.runtimeStr}}</span>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="软件信息">
            <span>{{form.sysKey && form.sysKey.version}}</span>
          </el-form-item>
        </el-col>

        <el-col :span="24" style="margin-top: 40px"><el-form-item label="授权信息"></el-form-item></el-col>
        <el-col :span="24">
          <el-form-item label="授权对象">
            <span>{{form.sysKey && form.sysKey.authuser}}</span>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="授权时间">
            <span>{{form.remainingTime}}</span>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="软件码">
            <div>{{form.softCode}}</div>
            <div class="cp-code" @click="copy(form.softCode)">(复制此软件码.用于生成激活码)</div>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="提交激活码" prop="versionKey" :rules="[{ required: true, message: '激活码 不能为空' }]">
            <el-input v-model="form.versionKey" style="min-height: 200px" type="textarea" placeholder="填写激活码"/>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="">
            <el-button type="primary" @click="tj">提交</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { authInfo, keyData } from "@/api/system/edittioninfo";
import {ElMessage} from "element-plus";

export default {
  data(){
    return{
      form: {},
    }
  },
  methods:{
    copy(str) {
      var save = function (e) {
        //设置需要复制模板的内容
        e.clipboardData.setData('text/plain',str);
        //阻止默认行为
        e.preventDefault();
      }
      // h5监听copy事件，调用save函数保存到模板中
      document.addEventListener('copy',save);
      // 调用右键复制功能
      document.execCommand('copy');
      //移除copy事件
      document.removeEventListener('copy',save);
      ElMessage({
        message: '复制成功',
        type: 'success',
      })
    },

    tj(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let { versionKey } = this.form;
          keyData({
            versionKey
          }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              this.getData();
            }
          })
        } else {
          return false
        }
      })

    },

    getData(){
      authInfo().then(res=>{
        this.form = res.data.data;
      })
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.cp-code{
  font-size: 18px;
  color: #848484;
  cursor: pointer;
}
</style>