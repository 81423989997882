import axios from "@/api/axios"; // 引用axios
export const authInfo = (params) => {
    return axios({
        url: "/api/version/authInfo",
        method: "post",
        params: params
    });
};

export const keyData = (params) => {
    return axios({
        url: "/api/version/keyData",
        method: "post",
        params: params
    });
};
