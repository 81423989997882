<template>
  <div>
    <el-button @click="visible = true; title = '新增'" style="display: block;margin-bottom: 20px" class="searchBtn">新增</el-button>

    <basicTable
        :tableData="tableData"
        :isPage="false"
        :attrList="attrList">
    </basicTable>

    <el-dialog append-to-body v-model="visible" :title="title" :close-on-click-modal="false" :before-close="handleClose">
      <el-form label-position="right" :model="form" size="small" label-width="120px" ref="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="参数项名称" prop="name" :rules="[{ required: true, message: '请输入 参数项名称' }]">
              <el-input clearable v-model="form.name" placeholder="请输入 参数项名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="title == '新增'">
            <el-form-item label="参数标识" prop="code" :rules="[{ required: true, message: '参数标识 不能为空' }]">
              <el-input clearable v-model="form.code" placeholder="请输入 参数标识" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参数值" prop="value" :rules="[{ required: true, message: '参数值 不能为空' }]">
              <el-input clearable v-model="form.value" placeholder="请输入 参数值" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述" prop="note" :rules="[{ required: true, message: '描述 不能为空' }]">
              <el-input clearable v-model="form.note" placeholder="请输入 描述" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onsend">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from "element-plus";
import { getSysParamAll, addData, editData, delData } from "@/api/system/configuration";
export default {
  components:{
  },
  data(){
    return{
      title:"",
      form:{},
      visible:false,
      tableData:[],
      attrList:[
        {
          prop: "name",
          label: "参数项名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "code",
          label: "参数标识",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "value",
          label: "参数值",
          render: (row) => <div>{row.value}</div>,
        },
        {
          prop: "note",
          label: "描述",
          render: (row) => <div>{row.note}</div>,
        },
        {
          prop: "",
          label: "编辑",
          render: (row) => (
              <div>
                <el-button type="text" icon="Edit" onClick={()=>this.dataEdit(row)}>
                  编辑
                </el-button>
                <el-button type="text" icon="Delete" onClick={()=>this.del(row)}>
                  删除
                </el-button>
              </div>
          )
        },
      ],
    }
  },
  methods:{
    handleClose(done){
      this.$nextTick(()=>{
        this.$refs['form'].resetFields();
        this.form = {};
      })
      done();
    },

    dataEdit(row){
      this.form = JSON.parse(JSON.stringify(row));
      this.title = '编辑';
      this.visible = true;
    },

    del(row){
      ElMessageBox.confirm(
          '此操作将永久删除该数据, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        delData({id:row.id}).then(res=>{
          ElMessage({message: res.data.code.message, type: 'success'});
          this.onLoad();
        })
      })
    },

    onsend(){
      this.$refs['form'].validate(valid=>{
        let { id, name, code, value, note } = this.form;
        if (valid) {
          if(this.title == '新增'){
            addData({
              name, code, value, note
            }).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                this.visible = false;
                this.onLoad();
                this.$nextTick(()=>{
                  this.form = {};
                  this.$refs['form'].resetFields();
                })
              }
            })
          }else{
            editData({
              id, name, value, note
            }).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                this.visible = false;
                this.$nextTick(()=>{
                  this.$refs['form'].resetFields();
                })
                this.onLoad();
              }
            })
          }
        }else {
          return false;
        }
      })
    },
    onLoad(){
      getSysParamAll().then(res=>{
        this.tableData = res.data.data;
      })
    }
  },
  mounted() {
    this.onLoad()
  }
}
</script>
<style lang="scss" scoped>

</style>